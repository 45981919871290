import { Box, Container, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';

const WelcomeText: FC = ({ children }) => (
  <Box marginY={5}>
    <Typography variant="h3">
      <strong>{children}</strong>
    </Typography>
  </Box>
);

const RedText: FC = ({ children }) => (
  <Box display="inline" color="primary.main">
    {children}
  </Box>
);

const IndexPage: FC = () => (
  <Layout>
    <Seo title="👋"/>

    <Container>
      <WelcomeText>
        Hi there! I'm <RedText>Mack</RedText> 👋
      </WelcomeText>

      <WelcomeText>
        I do <RedText>design</RedText>, <RedText>engineering</RedText>,<br/>
        & professional hat stacking 🎩
      </WelcomeText>

      <WelcomeText>
        I work at <RedText>Kasada</RedText> in sunny Sydney ☀️
      </WelcomeText>
    </Container>
  </Layout>
);

export default IndexPage;
